// ! ---------- LOGO

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
        @extend %hide-visually; }
    svg {
        width: $logo-width;
        max-width: $logo-max-width;
        height: auto;
        path {
            fill: palette('text'); } } }




// ! ---------- NAVIGATION


.navigation {

    position: relative;

    a {
        display: block;
        @extend %no-tap-highlight;
        @extend %main-headline; }

    > ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        > li {
            flex: 0 0 auto;
            > a {
                @extend %container-header-size;
                @include horizontal-border(bottom); }

            &:first-child > a {
                @include horizontal-border(top); }

            &.is-main {
                flex: 1 1 auto;

                display: flex;
                flex-direction: column;
                align-items: stretch;

                a {
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center; } } }

        ul {
            width: 100%;
            height: 100%;

            flex: 1 1 100%;

            display: flex;
            flex-direction: row;
            align-items: stretch;
            flex-wrap: wrap;

            li {
                flex: 0 0 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:nth-child(2) {
                    @extend %sub-container-border-left--always; }

                &:last-child {
                    flex-basis: 100%;
                    @include horizontal-border(top);
                    @include horizontal-border(bottom); } } } } }


$c: currentColor;
$t: transparent;

.navigation-toggle {
    @extend %container-header-size;
    @extend %container-header-width;
    @extend %no-tap-highlight;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        @include grid-gutter-property(right);
        width: 1.4em;
        height: 1px;
        background: $c;
        box-shadow: 0 -.45em 0 0 $c, 0 .45em 0 0 $c; }

    transition: transition(opacity);

    .is-nav-visible & {
        opacity: 0;
        pointer-events: none; } }



// ! ---------- FOOTER

.footer {
    @extend %accent-typography;
    @extend %accent-text;
    text-transform: uppercase;
    ul {
        display: inline-block; }
    li {
        display: inline-block; }
    li + li {
        padding-left: 2.5em; }
    li + li.sep-slash {
        padding-left: 0;
        &::before {
            padding: 0 .3em 0 .4em;
            content: '/'; } } }




// ! ---------- LANGUAGE SWITCHER


.language-switcher {
    @extend %accent-typography;
    text-transform: uppercase;

    ul {
        list-style: none;
        display: inline; }

    li {
        display: inline-block;

        > a, > span {
            &::before {
                content: attr(data-abbr); }
            span {
                @extend %hide-visually; } } }

    li + li {
        &::before {
            content: ' / ';
            white-space: pre; } } }


%page-container {
    text-align: center;

    @include layout--nav-always-visible {
        position: absolute;
        @include grid-gutter-property(top);
        @include grid-gutter-property(bottom);
        @include grid-gutter-property(left);
        @include grid-gutter-property(right);

        @include horizontal-border(top);
        @include horizontal-border(bottom);

        z-index: $z-index-content; } }



// ! ---------- BASIC PAGE

// ! ---------- LANDING PAGE


%basic-page-header {
    width: 100%;
    @include horizontal-border(bottom);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include layout--nav-always-visible {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1; } }

%basic-page-logo {
    @extend %container-header-size;
    display: block;
    @include horizontal-border(top);
    @include horizontal-border(bottom);
    flex: 0 0 100%;
    background: image-url('logo.svg') no-repeat 50% 50%;
    background-size: auto 40%;

    span {
        @extend %hide-text; } }

%basic-page-logo--dark {
    background-image: image-url('logo-dark.svg'); }

%basic-page-title {
    @extend %container-header-size;
    @extend %main-headline;
    flex: 0 0 100%; }

%basic-page-secondary-title {
    @extend %container-header-size;
    @extend %sub-headline;
    @extend %sub-container-border-left; }

%basic-page-main {

    position: relative;
    margin: 0 auto;
    max-width: 30em;
    padding-top: 1/2 * $headline-height;

    @include breakpoint(small) {
        @include main-container-border; }

    @include layout--nav-always-visible {
        position: absolute;
        top: 2 * $headline-height;
        left: 50%;
        width: percentage(18/18);
        transform: translateX(-50%);
        height: calc(100% - #{3 * $headline-height});
        z-index: 0;
        @include horizontal-border(top);
        width: percentage(12/18);
        max-width: none;
        padding-top: 0; } }


%basic-page-content {
    padding-bottom: $headline-height;

    @include breakpoint(small) {
        padding-left: percentage(1/12);
        padding-right: percentage(1/12); }

    @include layout--nav-always-visible {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        padding-bottom: $headline-height * 2; } }



.basic-page {
    @extend %page-container; }

.basic-page__header {
    @extend %basic-page-header; }

.basic-page__logo {
    @extend %basic-page-logo;
    @extend %basic-page-logo--dark; }

.basic-page__title {
    @extend %basic-page-title; }

.basic-page__link {
    @extend %basic-page-secondary-title; }

.basic-page__main {
    @extend %basic-page-main; }

.basic-page__content {
    @extend %basic-page-content; }




// ! ---------- LANDING PAGE


.landing-page {
    @extend %page-container; }

.landing-page__header {
    @extend %basic-page-header; }

.landing-page__logo {
    @extend %basic-page-logo; }

.landing-page__title {
    @extend %basic-page-title;
    flex: 1 0 50%; }

.landing-page__link {
    @extend %basic-page-secondary-title;
    flex: 1 0 50%;
    display: none;
    @include breakpoint(medium) {
        display: block; } }

.landing-page__main {
    @extend %basic-page-main; }

.landing-page__content {
    @extend %basic-page-content; }




// ! ---------- HOMEPAGE


.home {
    @extend %page-container;

    // Clear overlay to disable hover effects during transitions

    &::after {
        display: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(blue, 0.1);
        pointer-events: none;
        z-index: $z-index-shield;

        .is-content-transitioning & {
            pointer-events: auto;
            background: rgba(blue, 0.4); } } }

.page, .navigation-container, .page__content, .home, .home > * {
    background-color: inherit; }

.home__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-position--overlay;
    @extend %container-header-style;
    z-index: $z-index-header !important;
    @include layout--nav-always-visible {
        display: none; } }


// ! ---------- INFO


.info {

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-info &,
        .visible-container-default-state &, {
            max-height: none;
            visibility: visible; } }

    @include layout--nav-always-visible {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: $headline-height;
        overflow: hidden;
        transition: transition(height, $factor: $transition-container);
        cursor: pointer;
        z-index: $z-index-content;

        .visible-container-info & {
            height: calc(100% - #{3 * $headline-height});
            cursor: default; } } }

.info__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-style; }

.info__content {
    @extend %container-content-style;
    @extend %container-content-position;
    @extend %container-content-style--large;

    @include layout--nav-always-visible {
        padding-top: $headline-height; }

    .visible-container-info & {
        @extend %container-content-style--visible; } }



// ! ---------- PACKAGE LIST


body.visible-container-package-list,
body.template-basic-page, {
    background-color: palette('text');
    color: palette('background');
    --bg-color: palette('text');
    --text-color: palette('background'); }

.package-list {

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-package-list & {
            max-height: none;
            visibility: visible; } }

    @include layout--nav-always-visible {
        position: absolute;
        left: 0;
        bottom: calc(50%);
        width: 100%;
        height: calc(50% - #{$headline-height});
        overflow: hidden;
        transition: transition(height bottom, $factor: $transition-container), 100ms border-color;
        cursor: pointer;
        z-index: $z-index-content;

        @include horizontal-border(top);
        @include horizontal-border(bottom);
        border-bottom-color: transparent;

        .visible-container-info & {
            height: $headline-height;
            bottom: $headline-height * 2; }

        .visible-container-package-list & {
            height: calc(100% - #{3 * $headline-height});
            bottom: $headline-height * 2;
            cursor: default;
            z-index: $z-index-visible; }

        .is-content-not-transitioning.visible-container-about &,
        .is-content-not-transitioning.visible-container-legal &, {
            z-index: $z-index-preview; }

        .visible-container-project-list &,
        .visible-container-about &,
        .visible-container-legal &, {
            height: $headline-height;
            bottom: calc(100% - #{2 * $headline-height}); }

        .visible-container-info.previewed-container-package-list & {
            height: calc(50% - #{$headline-height}); }

        .visible-container-info.previewed-container-about &, {
            height: $headline-height;
            bottom: calc(50%); }

        .visible-container-about.previewed-container-package-list &, {
            height: calc(50% - #{$headline-height});
            bottom: calc(50%);
            border-bottom-color: currentColor; }

        .visible-container-info.previewed-container-package-list &,
        .visible-container-about.previewed-container-package-list &, {
            z-index: $z-index-preview; } } }


.package-list__list {

    display: flex;
    flex-direction: column;

    @include layout--nav-always-visible {

        flex-direction: row;

        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100%;
        transform: translateX(-50%);
        transition: transition(width transform, $factor: $transition-container);

        .is-resizing & {
            transition: none; }

        .visible-container-package-list & {
            width: 200%; }

        .visible-container-package-list.sub-container-1 & {
            transform: translateX(#{percentage(-1/6)}); }
        .visible-container-package-list.sub-container-2 & {
            transform: translateX(-50%); }
        .visible-container-package-list.sub-container-3 & {
            transform: translateX(#{-100% + percentage(1/6)}); } } }

.package-list__title {
    @extend %hide-visually; }

.package {
    flex: 1;
    position: relative; }

.package {

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-package-list.sub-container-1 &:nth-child(1),
        .visible-container-package-list.sub-container-2 &:nth-child(2),
        .visible-container-package-list.sub-container-3 &:nth-child(3), {
            max-height: none;
            visibility: visible; } }

    // Left outer-most and in-between left borders
    @extend %sub-container-border-left;

    // Right outermost border
    &:last-child {
        @extend %sub-container-border-right; }

    // Hide outermost borders until section visible
    &:first-child::before,
    &:last-child::after, {
        opacity: 0;
        .visible-container-package-list & {
            opacity: 1; } } }


.package__title {
    @extend %sub-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-style; }

.package__content {
    @extend %container-content-position;
    @extend %container-content-style;
    overflow: hidden;
    @include layout--nav-always-visible {
        max-width: 60em; }

    .visible-container-package-list & {
        @extend %container-content-style--visible; } }

.package__title,
.package__content {
    @include layout--nav-always-visible {
        opacity: 1;
        .visible-container-package-list & {
            opacity: 0;
            pointer-events: none; }
        .visible-container-package-list.sub-container-1 .package:nth-child(1) &,
        .visible-container-package-list.sub-container-2 .package:nth-child(2) &,
        .visible-container-package-list.sub-container-3 .package:nth-child(3) &, {
            opacity: 1;
            pointer-events: auto; } } }

.package__content {
    @include layout--nav-always-visible {
        transition: transition(opacity, $factor: $transition-container); } }

.package__preview {
    @extend %container-preview-size;
    display: flex;
    flex-direction: column;

    .package-list__package:nth-child(1) &,
    .package-list__package:nth-child(2) &, {
        padding-top: 0 !important; } }

.package__illustration {
    flex: 1;
    margin-bottom: $one-line-height;

    @include layout--nav-always-visible {
        margin-bottom: 0;
        padding-bottom: 4vh;
        .firefox & {
            padding-bottom: 0; }

        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: flex-start;

        transition: transition(padding, $factor: $transition-container);
        .visible-container-package-list & {
            padding-bottom: 0; } }

    svg {
        width: 87%;
        //height: auto
        //max-height: 100%
        object-fit: auto;
        object-position: 50% 10%;

        @extend %container-preview-illustration-size;
        @include layout--nav-always-visible {
            width: 100%; } }

    g[stroke] {
        stroke: currentColor; }

    path {
 }        // fill: currentColor

    @extend %non-scaling-svg-paths; }



.package__summary {
    @extend %accent-text;
    @include grid-gutter-padding(bottom);

    display: none;
    @include layout--nav-always-visible {
        display: block;
        transition: transition(opacity max-height, $factor: $transition-container / 2);
        max-height: 4 * $one-line-height;
        .visible-container-package-list & {
            opacity: 0;
            max-height: 0; } } }


.package__main {
 }    // opacity: 0

.package__announcement {
    margin-bottom: $one-line-height * 1.5;
    @include breakpoint(small) {
        margin-bottom: $one-line-height * 2; }
    @include breakpoint(large) {
        margin-bottom: $one-line-height * 3;
 } }        // margin-top: $one-line-height * 1/2


.package__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    transition: transition(opacity, $factor: $transition-container);

    .visible-container-package-list & {
        opacity: 0;
        pointer-events: none; }

    display: none;
    @include layout--nav-always-visible {
        display: block; } }

.package-list__prev,
.package-list__next {
    position: absolute;
    top: 0;
    left: 0;
    width: percentage(3/18);
    height: 100%;
    transition: transition(opacity, $factor: $transition-container);
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;

    display: none;
    @include layout--nav-always-visible {
        display: block; }

    .visible-container-package-list & {
        opacity: 1;
        pointer-events: auto; }

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 2rem;
        height: 2rem;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: currentColor;
            z-index: -1;
            transform-origin: 0% 0%; }

        &::before {
            top: 0;
            right: 0;
            @include grid-gutter-property(left, $factor: 1/2);
            @include horizontal-border(top);
            transform: rotate(8deg); }

        &::after {
            left: 0;
            bottom: 0;
            @include grid-gutter-property(top, $factor: 1/2);
            @include horizontal-border(left);
            transform: rotate(-8deg); } } }


.package-list__prev {
    //background: red
    .visible-container-package-list.sub-container-1 & {
        opacity: 0;
        pointer-events: none; } }

.package-list__next {
    //background: green
    left: auto;
    right: 0;
    span {
        transform: translate(-50%, -50%) rotate(135deg); }
    .visible-container-package-list.sub-container-3 & {
        opacity: 0;
        pointer-events: none; } }


// ! ---------- ABOUT PARENT


// Wrapper of projects + about

.about-parent {

    @include layout--nav-always-visible {
        position: absolute;
        left: 0;
        bottom: $headline-height;
        width: 100%;
        height: calc(50% - #{$headline-height});
        overflow: hidden;
        transition: transition(height, $factor: $transition-container);
        cursor: pointer;
        z-index: $z-index-content;
        @include horizontal-border(top);

        display: flex;
        flex-direction: row;

        .visible-container-about & {
            height: calc(100% - #{3 * $headline-height});
            cursor: default;
            z-index: $z-index-visible; }

        .visible-container-info &,
        .visible-container-package-list &, {
            height: $headline-height; }

        .is-content-not-transitioning.visible-container-package-list & {
            z-index: $z-index-preview; }

        .visible-container-info.previewed-container-about &,
        .visible-container-package-list.previewed-container-about &, {
            height: calc(50% - #{$headline-height}); }

        .visible-container-info.previewed-container-about &,
        .visible-container-package-list.previewed-container-about &, {
            z-index: $z-index-preview; } } }


// ! ---------- PROJECT LIST


.project-list {
    flex: 0 0 50%;
    position: relative;

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-project-list & {
            max-height: none;
            visibility: visible; } } }

.project-list__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-style; }

.project-list__content {
    @extend %container-content-position;
    @extend %container-content-style;

    @include layout--nav-always-visible {
        overflow: hidden;
        .scroll-container {
            pointer-events: none; } }

    .visible-container-about & {
        @extend %container-content-style--visible;
        .scroll-container {
            pointer-events: auto; } } }

.project-list__detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-overlay;

    @include layout--nav-as-overlay {
        background: palette('background');
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none; }

    @include layout--nav-always-visible {
        position: absolute;
        z-index: 3; } }

.project-preview {
    user-select: none;

    @include layout--nav-always-visible {
        pointer-events: none;
        .visible-container-about & {
            pointer-events: auto; } } }

.project-preview__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @extend %container-preview-size; }

.project-preview__header {
    @extend %accent-text;
    order: 1;
    margin-top: 1em; }

.project-preview__title,
.project-preview__date {
    display: inline; }

.project-preview__title {
    margin-right: 1em; }

.project-list {
    .project-preview, {
        img, video {
            @extend %container-preview-image-size;
            color: inherit;
            @include vertical-border(top);
            @include vertical-border(bottom);
            @include horizontal-border(left);
            @include horizontal-border(right); }

        .image, .video {
            border-width: 0 !important; } }

    .project-detail {

        @if true {
            .video {
                border-width: 0 !important; }

            video {
                color: inherit;
                @include vertical-border(top);
                @include vertical-border(bottom);
                @include horizontal-border(left);
                @include horizontal-border(right); } } } }



.project-detail__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-position--overlay;
    @extend %container-header-style; }

.project-detail__close {
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-overlay-close;
    @extend %close-button;
    @extend %close-button--headline;

    @include layout--nav-as-overlay {
        @include grid-gutter-property(top); }
    @include layout--nav-always-visible {
        position: absolute;
        z-index: 4; } }

.project-detail,
.project-detail__close, {
    transition: transition(opacity);
    opacity: 0;
    pointer-events: none;
    .is-project-detail-visible & {
        opacity: 1;
        pointer-events: auto; } }

.project-list__content {
    transition: transition(opacity);
    .is-project-detail-loading,
    .is-project-detail-visible &, {
        opacity: 0;
        pointer-events: none; } }

.project-detail__main {
    @extend %container-content-position;
    @extend %container-content-style;
    @extend %container-content-style--visible;
    overflow: hidden;

    padding-top: $one-line-height; }

.project-detail__content {
    @include layout--nav-as-overlay {
        @include grid-gutter-padding(left right);
        padding-top: $headline-height; }
    @include layout--nav-always-visible {
        padding: inherit; } }

// ! ---------- ABOUT


.about {
    flex: 0 0 50%;
    @extend %sub-container-border-left;

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-about & {
            max-height: none;
            visibility: visible; } } }

.about__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-style; }

.about__content {
    @extend %container-content-position;
    @extend %container-content-style;

    @include layout--nav-always-visible {
        overflow: hidden;
        .scroll-container {
            pointer-events: none; } }

    .visible-container-about & {
        @extend %container-content-style--visible;
        .scroll-container {
            pointer-events: auto; } } }

.about__intro {
    @extend %container-preview-size;
    @extend %large-intro-text--constrained;

    display: flex;
    justify-content: center;
    align-items: center; }



// ! ---------- LEGAL


// Wrapper of imprint + privacy page

.legal-parent {

    // Mobile: display below About

    @include layout--nav-as-overlay {

        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        .visible-container-about & {
            max-height: none;
            visibility: visible; } }

    @include layout--nav-always-visible {
        position: absolute;
        left: 0;
        bottom: $headline-height;
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: transition(height, $factor: $transition-container);
        cursor: pointer;
        z-index: $z-index-content;
        @include horizontal-border(top);

        display: flex;
        flex-direction: row;

        .visible-container-legal & {
            height: calc(100% - #{3 * $headline-height});
            cursor: default;
            z-index: $z-index-visible; } } }



.legal {
    position: relative;

    @if false {
        @include layout--nav-as-overlay {

            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            .visible-container-about & {
                max-height: none;
                visibility: visible; } } }

    @include layout--nav-always-visible {
        flex: 0 0 50%;
        position: relative; } }

.legal + .legal {
    @extend %sub-container-border-left; }

.legal__title {
    @extend %main-headline;
    @extend %container-header-size;
    @extend %container-header-position;
    @extend %container-header-style; }

.legal__content {
    @extend %container-content-position;
    @extend %container-content-style;
    padding-top: 2 * $one-line-height;

    @include layout--nav-always-visible {
        overflow: hidden;
        .scroll-container {
            pointer-events: none; } }

    .visible-container-legal & {
        @extend %container-content-style--visible;
        .scroll-container {
            pointer-events: auto; } } }

.legal__close {
    position: fixed;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-overlay-close;
    @extend %close-button;
    @extend %close-button--headline;

    @include layout--nav-as-overlay {
        @include grid-gutter-property(top);
        display: none; }

    @include layout--nav-always-visible {
        position: absolute;
        z-index: 4; } }



// ! ---------- PROJECT LIST




// ! ---------- CONTENT MATRIX


.content-matrix {
    @include grid-row(); }

.content-matrix__item {}

.content-item {
    @include layout--two-column-content {
        @include grid-column(); } }

.content-item + .content-item {
    margin-top: $one-line-height * 1.5;
    // margin-top: $one-line-height--absolute
    @include breakpoint(medium) {
        margin-top: $one-line-height * 2;
        margin-top: $one-line-height--absolute; } }


.content-width--50:first-child + .content-width--50 {
    @include layout--two-column-content {
        margin-top: 0; } }

.content-item--space {
    height: 1px;
    @include layout--nav-always-visible {
        height: calc(#{$headline-height * 1.05} - #{2 * $one-line-height--absolute}); } }

.content-item--anchor {
    height: 1px;
    margin: 0 !important; }

.content-item--image {}

.video {
    overflow: hidden; }

.image {
    @include vertical-border(top);
    @include vertical-border(bottom);
    @include horizontal-border(left);
    @include horizontal-border(right);
    position: relative;
    figure {
        @extend .ratio-container; }
    figcaption {
        @extend %hide-visually; }
    img {
        width: 100%;
        height: auto; } }

.image--with-illustration {
    border-width: 0 !important;

    &.image--with-illustration--room-info {
        @extend .ratio-container;
        overflow: visible;
        padding-bottom: 78% * 1.3; // 87.2 = illustration ratio, 130% = transform factor
        @include layout--nav-always-visible {
            padding-bottom: 87% * 1.3; } } } // 87.2 = illustration ratio, 130% = transform factor

.image__illustration {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translateX(-50%);

    svg {
        width: 100%;
        height: auto; }

    @extend %non-scaling-svg-paths;

    #layer-photo {
        display: none; }

    &.illustration--room-info {
        width: 130%;

        path {
            transition: transition(opacity, $factor: 3, $easing: ease-in-out), transition(transform, $factor: 6, $easing: ease-in-out);
            transition-delay: $transition-container * $transition-duration, $transition-container * $transition-duration * 1.5;
            opacity: 0;

            .visible-container-info & {
                opacity: 1;
                @include layout--nav-always-visible {
                    transform: none !important; } }

            @include layout--nav-as-overlay {
                opacity: 1; } }

        #layer-frame path {
            @include layout--nav-as-overlay {
                display: none; }
            @include layout--nav-always-visible {
                transform: translateX(-4rem); } }

        #layer-frame-small path {
            @include layout--nav-always-visible {
                display: none; } }

        #layer-display path {
            @include layout--nav-always-visible {
                transform: translateX(-4rem); } }

        #layer-table path {
            transform: translate(15%, 0);
            @include layout--nav-always-visible {
                transform: translate(0, 1rem); } }

        #layer-lamp path {
            @include layout--nav-always-visible {
                transform: translateY(2rem); } }

        #layer-decoration path {
            @include layout--nav-always-visible {
                transform: translateY(-2rem); } }

        #layer-lines path {
            transform: translateY(-10%);
            @include layout--nav-always-visible {
                transform: translateY(5rem); } }

        #layer-clock path {
            transform: translateY(-10%);
            @include layout--nav-always-visible {
                transform: translateY(-2rem); } } } }

.content-item--video {

    .video {
        @include vertical-border(top);
        @include vertical-border(bottom);
        @include horizontal-border(left);
        @include horizontal-border(right); }

    video {
        width: 100%;
        max-width: 100%;
        height: auto; } }


.content-style--align-left {
    text-align: left; }

.content-style--large-text {
    @extend %large-intro-text; }

.content-style--border-top {
    @include horizontal-border(top);
    padding-top: 2em; }

.content-style--border-bottom {
    @include horizontal-border(bottom);
    padding-bottom: 2em; }

.content-style--padding-left {
    @include layout--nav-always-visible {
        padding-left: percentage(1/12) !important; } }

.content-style--padding-right {
    @include layout--nav-always-visible {
        padding-right: percentage(1/12) !important; } }

.content-width--100 {}

.content-width--50 {
    @include layout--two-column-content {
        @include grid-column-size(1/2); } }




// ! ---------- ANNOUNCEMENTS


.announcement {
    border-top: 1px solid;
    text-align: center; }

.announcement__row {
    padding: $table-gutter 0;
    border: 1px solid;
    border-width: 0 0 1px 0; }

.announcement__title,
.announcement__content {
    border: 1px solid;
    border-width: 0 1px;
    margin: $table-gutter 0;
    padding: $table-padding/2 0; }

.announcement__title {
    @extend %table-header-text;
    position: relative; }

.announcement__close {
    position: absolute;
    top: 50%;
    right: $table-padding/2;
    z-index: 4;
    transform: translateY(-50%);
    @extend %close-button; }

.announcement__content {
    @extend %large-intro-text;
    padding: $table-padding*2/3 1em;
    @include breakpoint(small) {
        padding: $table-padding*2/3 2em; } }




// ! ---------- SLIDESHOW


.slideshow {}

.slideshow__outer {
    position: relative; }

.slideshow {
    .image--portrait {
        width: 50%;
        margin-left: auto;
        margin-right: auto; } }

.slideshow__arrow-left,
.slideshow__arrow-right, {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    transition: transition(opacity);
    cursor: pointer;
    z-index: 1;
    outline: none;
    @extend %no-tap-highlight;

    display: none;
    @include layout--nav-always-visible {
        display: block; }

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(10%, -50%) rotate(-45deg);
        width: 2rem;
        height: 2rem;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: currentColor;
            z-index: -1;
            transform-origin: 0% 0%; }

        &::before {
            top: 0;
            right: 0;
            @include grid-gutter-property(left, $factor: 1/2);
            @include horizontal-border(top);
            transform: rotate(8deg); }

        &::after {
            left: 0;
            bottom: 0;
            @include grid-gutter-property(top, $factor: 1/2);
            @include horizontal-border(left);
            transform: rotate(-8deg); } } }

.slideshow__arrow-right {
    left: auto;
    right: 0;
    span {
        left: auto;
        right: 0;
        transform: translate(-10%, -50%) rotate(135deg); } }

.slideshow__outer {
    @include breakpoint(xlarge) {
        .slideshow__arrow-left {
            transform: translateX(-5rem); }
        .slideshow__arrow-right {
            transform: translateX(5rem); } } }


// ! ---------- ILLUSTRATION SLIDESHOW


.illustration-slideshow {}

.illustration-slideshow__header {
    @extend %sub-headline;
    &:empty {
        display: none; } }

.illustration-slideshow__illustration,
.illustration-slideshow__text, {
    margin-left: auto;
    margin-right: auto;
    max-width: 28rem;
    @include layout--font-size-reduced {
        max-width: 28rem * (1 / $fontsize-factor--reduced); } }

.illustration-slideshow__text {
    @extend %accent-text; }

.illustration-slideshow__illustration {
    margin-top: $one-line-height * 2;
    margin-bottom: $one-line-height * 2;
    svg {
        width: 100%;
        height: auto; } }




// ! ---------- TABLES


.text-block {

    table {
        @extend %table-text;
        border-top: 1px solid; }

    table, thead, tbody {
        display: block;
        width: 100% !important; }

    tr {
        display: flex;
        padding: $table-gutter 0;
        border: 1px solid;
        border-width: 0 0 1px 0; }

    td, th {
        display: block;
        flex: 1 1 auto;
        border: 1px solid;
        border-width: 0 1px;
        text-align: center;
        margin: $table-gutter 0;
        padding: $table-padding 1em;

        &:only-child {
            padding-top: $table-padding/2;
            padding-bottom: $table-padding/2; } }

    th:nth-last-child(n+2), th:nth-last-child(n+2) ~ th,
    td:nth-last-child(n+2), td:nth-last-child(n+2) ~ td {
        max-width: percentage(1/2); }

    th:nth-last-child(n+3), th:nth-last-child(n+3) ~ th,
    td:nth-last-child(n+3), td:nth-last-child(n+3) ~ td {
        max-width: percentage(1/3); }

    th {
        @extend %table-header-text; }

    td {
        @extend %table-cell-text; }

    td+td, th+th {
        border-left-width: 0; }

    table.is-collapsible {
        thead {
            cursor: pointer; } } }




// ! ---------- FAQs


.faq {}

.faq__row {
    margin-bottom: 3 * $one-line-height; }

.faq__question {
    @extend %main-headline; }

.faq__answer {
    @extend %main-headline;
    margin-top: 1/2 * $one-line-height; }
