// ! ---------- LAYOUT


.page__header,
.page__content,
.page__footer,
.navigation-container {
    @if $splash-screen-enabled {
        transition: transition(transform opacity, $factor: $transition-splash);
        .is-splash-screen-visible &,
        .is-resizing &, {
            transition: none; } } }

.navigation-container {
    transition-duration: $transition-duration; }

.page__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: palette('background');
    z-index: $z-index-splash;
    display: none;

    @if $splash-screen-enabled {
        display: block;
        pointer-events: none;
        transform: translate3d(0, -101vh, 0);

        .is-splash-screen-visible &,
        .is-global-announcement-visible &,
        .is-user-idle &, {
            pointer-events: auto;
            transform: none; } } }

.page__footer {

    display: none;

    @include layout--nav-always-visible {
        display: block;
        position: fixed;
        @include grid-gutter-property(bottom);
        @include grid-gutter-property(left);
        @include grid-gutter-property(right);
        @include horizontal-border(top);
        @include horizontal-border(bottom);
        z-index: $z-index-footer; } }


.footer__inner {
    @extend %container-header-size;
    @extend %container-header-size--small-font;
    @extend %container-header-style;
    display: flex;
    > * {
        flex: 0 0 50%; }
    > :last-child {
        text-align: right; } }

.page__content {
    @include layout--nav-always-visible {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    @include layout--nav-as-overlay {
        @include grid-gutter-padding(top left right bottom); } }

.page__content,
.navigation-container {

    @if $splash-screen-enabled {
        .is-splash-screen-visible &,
        .is-global-announcement-visible &,
        .is-user-idle &, {
            transform: translate3d(0, 101vh, 0); } } }




.navigation-container {
    @include layout--nav-as-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: palette('background');
        z-index: $z-index-nav;

        display: flex;
        flex-direction: column;
        text-align: center;

        pointer-events: none;
        opacity: 0;

        .is-nav-visible & {
            opacity: 1;
            pointer-events: auto; }

        @include grid-gutter-padding(top left right bottom);

        .navigation {
            flex: 1 0 auto; }
        .language-switcher {
            flex-grow: 0;
            flex-shrink: 0;
            @include horizontal-border(bottom); } } }

.navigation-toggle {
    position: fixed;
    right: 0;
    @include grid-gutter-property(top);
    z-index: $z-index-nav-toggle; }

.navigation,
.navigation-toggle {
    @include layout--nav-always-visible {
        display: none; } }


.language-switcher {

    @extend %container-header-size;
    @extend %container-header-size--small-font;

    @include layout--nav-always-visible {
        position: fixed;
        display: inline-block;
        @include grid-gutter-property(top);
        @include grid-gutter-property(right);
        z-index: $z-index-lang; } }


.page__announcement {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(2%);
    opacity: 0;
    pointer-events: none;
    z-index: $z-index-announcement;
    transition: transition(opacity, $factor: 2);
    cursor: pointer;
    @include grid-gutter-property(padding);

    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 50vh !important;

    .template-home.is-global-announcement-visible & {
        pointer-events: auto;
        opacity: 1; } }

.page__announcement__inner {
    width: 100%;
    max-width: 50rem;
    cursor: default;

    &::before {
        content: '';
        display: block;
        @include logo-height($factor: 1/2); }

    .announcement {
        background: palette('text');
        color: palette('background'); } }


.cookie-notice {
    position: fixed;
    @include grid-gutter-property(bottom);
    @include grid-gutter-property(left);
    @include grid-gutter-property(right);
    z-index: $z-index-cookie;
    @extend %accent-typography;

    @include layout--nav-always-visible {
        @include horizontal-border(top);
        @include horizontal-border(bottom); }

    transition: transition(transform, $factor: 3);
    transform: translateY(150%);
    .is-cookie-notice-visible & {
        transform: none; } }

.cookie-notice__inner {
    @extend %container-header-size;
    @extend %container-header-size--small-font;
    @extend %container-header-style;
    display: flex;
    justify-content: space-between;
    box-shadow: none !important;

    > * {
        flex: 0 1 auto; }
    > :first-child {
        @extend %truncate-ellipsis; }
    > :last-child {
        padding-left: 1em;
        text-align: right;
        flex: 1 0 auto; }

    a, button {
        text-transform: uppercase;
        white-space: nowrap; } }

.cookie-notice__accept {
    @extend %neutral-button;
    @include breakpoint(small down) {
        &::after {
            content: attr(data-text-short); }
        span {
            @include hide-visually; } } }
