// ! ---------- FONTS


$font-plantin: 'PlantinStd';
$font-eesti-book: 'EestiBook';
$font-stack-default: $font-plantin, Garamond, serif;
$font-stack-accent: $font-eesti-book, sans-serif;

@include font-face($font-plantin, normal, normal, 'plantin/369E25_1_0', $formats: woff2 woff ttf);
@include font-face($font-plantin, normal, italic, 'Plantin/369E25_0_0', $formats: woff2 woff ttf);
@include font-face($font-eesti-book, normal, normal, 'eesti/GT-Eesti-Text-Book', $formats: woff2 woff ttf);


// ! ---------- TYPOGRAPHY


%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base; }

%accent-typography {
    font-family: $font-stack-accent; }



// ! ---------- HEADLINES


%headline {
    text-align: center; }

%main-headline {
    @extend %headline;
    font-size: $fontsize-l;
    line-height: $lineheight-s;
    letter-spacing: $letterspacing-l;

    @include breakpoint(small) {
        font-size: $fontsize-l; }
    @include breakpoint(medium) {
        font-size: $fontsize-xl; } }

%large-intro-text {
    @extend %main-headline; }

%large-intro-text--constrained {
    @extend %headline;
    font-size: $fontsize-l;
    line-height: $lineheight-s;
    letter-spacing: $letterspacing-l;

    @include breakpoint(small) {
        font-size: $fontsize-l; }

    @media (min-height: 600px) {
        @include breakpoint(medium) {
            font-size: $fontsize-l; } }

    @media (min-height: 700px) {
        @include breakpoint(large) {
            font-size: ($fontsize-l + $fontsize-xl) / 2; } }

    @media (min-height: 750px) {
        @include breakpoint(xlarge) {
            font-size: $fontsize-xl; } } }

%sub-headline {
    @extend %headline;
    font-size: $fontsize-m;
    line-height: $lineheight-m;
    letter-spacing: $letterspacing-xl;
    text-transform: uppercase;

    @include breakpoint(small) {
        font-size: ($fontsize-m + $fontsize-l) / 2; }
    @include breakpoint(medium) {
        font-size: $fontsize-l;
        letter-spacing: $letterspacing-xxl; } }

%accent-text {
    @extend %accent-typography;
    font-size: $fontsize-s;
    // line-height: $lineheight-xs-s
    letter-spacing: $letterspacing-l;
    @include layout--font-size-reduced {
        font-size: $fontsize-m; } }

%table-text {
    @extend %accent-text;
 }    // line-height: $lineheight-xs-s

%table-header-text {
    @extend %table-text;
    letter-spacing: $letterspacing-xl;
    text-transform: uppercase; }

%table-cell-text {
    @extend %table-text;
 }    // line-height: $lineheight-l




// ! ---------- STYLES


%headline-height {
    height: $headline-height;
 }    // max-height: $fontsize-xl * 2.9

%container-header-size {
    height: $headline-height;
    line-height: $headline-height;
    @extend %truncate-ellipsis;
    padding-left: .5em;
    padding-right: .5em; }

%container-header-size--small-font {
    line-height: $headline-height * 1.1; }

%container-header-width {
    width: $headline-height; }

%container-header-style {
    background: var(--bg-color);

    @include layout--nav-as-overlay {
        transition: transition(box-shadow, $factor: $transition-container);
        @include horizontal-border(top);
        @include horizontal-border(bottom);
        box-shadow: 0 -13px 0 0 var(--bg-color); }

    @include layout--nav-always-visible {
        transition: transition(background box-shadow opacity transform, $factor: $transition-container);
        @if $header-shadow-enabled {
            box-shadow: $header-shadow var(--bg-color); } }

    .visible-container-package-list & {
        @extend %container-header-style--inverted; } }

%container-header-style--inverted {
    @include layout--nav-always-visible {
        background: palette('text');
        @if $header-shadow-enabled {
            box-shadow: $header-shadow palette('text'); } } }

%container-header-position {
    @include layout--nav-as-overlay {
        @if $sticky-section-headers {
            position: sticky;
            @include grid-gutter-property(top);
            z-index: $z-index-header; } }

    @include layout--nav-always-visible {
        position: absolute;
        top: 0;
        left: 1%;
        width: 98%;
        z-index: 2;

        .visible-container-info .info.is-section-scrolled &,
        .visible-container-about .about.is-section-scrolled &,
        .visible-container-about .project-list.is-section-scrolled &,
        .visible-container-project-list .project-list.is-section-scrolled &,
        .visible-container-project-list .about.is-section-scrolled &,
        .visible-container-package-list .package-list .is-section-scrolled &,
        .visible-container-legal .legal.is-section-scrolled &, {
            transform: translateY(-110%); } } }

%container-header-position--overlay {
    @include layout--nav-as-overlay {
        position: fixed;
        @include grid-gutter-property(top);
        @include grid-gutter-property(left);
        @include grid-gutter-property(right);
        z-index: $z-index-overlay-title; } }

%container-content-position {
    @include layout--nav-always-visible {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        z-index: 1; } }

%container-content-padding {
    &::before {
        content: ' ';
        display: block;
        @extend %container-header-size; }

    .firefox &::after {
        content: ' ';
        display: block;
        height: 3 * $one-line-height; }

    @include layout--nav-as-overlay {
        &::before, &::after {
            display: none !important; } } }

%container-content-style {
    padding: 0 0 #{2 * $one-line-height};
    max-width: 38em;
    @extend %container-content-padding;

    @include breakpoint(medium) {
        padding-bottom: 3 * $one-line-height; }

    @include layout--nav-as-overlay {
        margin-left: auto;
        margin-right: auto; }

    @include layout--nav-always-visible {
        padding-left: 5vw;
        padding-right: 5vw;
        max-width: 43em; } }


%container-content-style--large {
    @include layout--nav-always-visible {
        max-width: none;
        padding-left: 11vw;
        padding-right: 11vw; }
    @include breakpoint(xxlarge) {
        padding-left: 22vw;
        padding-right: 22vw; } }



%container-content-style--visible {
    //@extend %scrollable
    @include layout--nav-always-visible {
        @include hidden-scrollbars; } }

%container-preview-size {
    position: relative;

    @include layout--nav-as-overlay {
        padding-top: $one-line-height * 1.5;
        padding-bottom: $one-line-height * 0.5; }

    @include layout--nav-always-visible {
        height: calc(50vh - #{$headline-height * 1.5});
        height: calc(50vh - #{$headline-height * 2});
        @include grid-gutter-property(top, $factor: -1); } }

%container-preview-image-size {
    height: 33vh;

    width: auto;
    max-width: 100%;
    object-fit: cover;

    @include layout--nav-as-overlay {
        &.portrait {
            height: 50vh; } }

    @include layout--nav-always-visible {
        height: calc( ( 50vh - #{$headline-height * 1.5} ) - #{$one-line-height--absolute * 4}); } }

%container-preview-illustration-size {
    $l: $one-line-height--absolute;
    $base: ( 50vh - #{$headline-height * 1.5} );

    height: auto;

    @include layout--nav-always-visible {
        transition: transition(height);

        height: calc(#{$base} - #{$l * 3});
        .visible-container-package-list & {
            height: calc(#{$base} - #{$l * 1}); }

        @media (min-height: 550px) {
            height: calc(#{$base} - #{$l * 3});
            .visible-container-package-list & {
                height: calc(#{$base} - #{$l * 1}); } }

        @media (min-height: 650px) {
            height: calc(#{$base} - #{$l * 4});
            .visible-container-package-list & {
                height: calc(#{$base} - #{$l * 2}); } }

        .package-list {} } }



// Paint border to the left of a child of a content container

@mixin main-container-border {
    // position: relative

    &::before,
    &::after, {
        content: '';
        position: absolute;
        @include grid-gutter-property(top, $factor: 1/2);
        @include grid-gutter-property(bottom, $factor: 1/2);
        width: $border-width--vertical;
        z-index: -1; }

    &::before {
        left: 0;
        transform: translateX(-50%);
        @include vertical-border(left); }

    &::after {
        right: 0;
        transform: translateX(50%);
        @include vertical-border(right); } }

%main-container-border {
    @include main-container-border; }

@mixin sub-container-border-left {
    &::before {
        content: '';
        position: absolute;
        left: 0;
        @include grid-gutter-property(top, $factor: 1/2);
        @include grid-gutter-property(bottom, $factor: 1/2);
        width: $border-width--vertical;
        transform: translateX(-50%);
        @include vertical-border(left);
        transition: transition(opacity);
        z-index: -1; } }

%sub-container-border-left {
    position: relative;
    @include layout--nav-always-visible {
        @include sub-container-border-left; } }

%sub-container-border-left--always {
    position: relative;
    @include sub-container-border-left; }

%sub-container-border-right {
    position: relative;
    @include layout--nav-always-visible {
        &::after {
            content: '';
            position: absolute;
            right: 0;
            @include grid-gutter-property(top, $factor: 1/2);
            @include grid-gutter-property(bottom, $factor: 1/2);
            width: $border-width--vertical;
            transform: translateX(-50%);
            @include vertical-border(right);
            transition: transition(opacity);
            z-index: -1; } } }

%close-button {
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 1.6em;
        height: $border-width--horizontal;
        background: currentColor; }

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg); } }

%close-button--headline {
    width: $headline-height;
    height: $headline-height; }

// Non-scaling strokes in SVGs

%non-scaling-svg-paths {

    .no-chrome & {

        path {
            vector-effect: non-scaling-stroke; }

        // Old version: thin strokes
        path[stroke-width='0.5'], path[stroke-width='.5'],
        // New version: thin strokes
        path[stroke-width='1.58'],
        path[stroke-width='1.74'], {
            stroke-width: $border-width--horizontal;
            stroke-width: $border-width--svg-horizontal; }

        // Old version: thick strokes
        path[stroke-width='1.5'],
        // New version: thick strokes
        path[stroke-width='4.74'], {
            stroke-width: $border-width--vertical;
            stroke-width: $border-width--svg-vertical; } } }

@mixin logo-height($factor: 1) {
    height: $logo-width * $logo-ratio * $factor;
    max-height: $logo-max-width * $logo-ratio * $factor; }


// ! ---------- MEDIA QUERIES


// Save breakpoints to meta tag using Foundation utility method
// Read back in via JS

.js-config-breakpoints {
    font-family: '#{-zf-bp-serialize($breakpoints)}'; }

.js-config-container-transition {
    $duration: to-unit(ms, $transition-duration * $transition-container);
    $duration: strip-unit($duration);
    font-family: '#{$duration}'; }



// ! ---------- BASIC STYLES


html {
    @extend %basic-typography;
    box-sizing: border-box;
    font-size: $fontsize-base;
    @include layout--font-size-reduced {
        font-size: $fontsize-base * $fontsize-factor--reduced; }

    --vh-offset: 0;
    --bg-color: palette('background');
    --text-color: palette('text'); }

*,
*::before,
*::after {
    box-sizing: inherit; }

html {
    overflow-x: hidden; }

body {
    background: palette('background');
    color: palette('text');
    font-size: $fontsize-m;
    line-height: $lineheight-base;
    transition: transition(background-color color, $factor: $transition-container);

    @if $font-smoothing {
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale; }

    @include layout--nav-always-visible {
        overflow: hidden; } }

a {
    color: inherit;
    text-decoration: none;

    &:hover, &.hover {
        color: inherit;
        text-decoration: none; } }

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

input,
td {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }

::placeholder {
    line-height: normal; }

select {
    width: 100%; }

.hide-visually,
.sr-only {
    @extend %hide-visually; }

.hide-text {
    @extend %hide-text; }

// Show/hide elements in JavaScript

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.is-loading,
.is-loading input,
.is-loading a,
body.is-loading * {
    cursor: wait !important; }





// ! ---------- TEXT SELECTION


::selection {
    background: palette('selection'); }

::-moz-selection {
    background: palette('selection'); }



// ! ---------- IMAGE LAZYLOADING


// Fade in after load

.lazyload,
.lazyloading {
    opacity: 0; }

.lazyloaded {
    opacity: 1;
    transition: transition(opacity); }


// Blur lazyloaded images before unveiling

.lazyloadblur {
    opacity: 1;
    filter: blur(20px);
    transition: transition(filter);

    &.lazyloaded {
        filter: blur(0); } }


// Hide lazyload images on non JS browsers

.no-js .lazyload {
    display: none !important; }

.no-js noscript .lazyload {
    display: block !important;
    opacity: 1; }



// ! ---------- FLOATING


.clearfix {
    @include clearfix; }



// ! ---------- INTRINSIC RATIOS


.ratio-container {
    position: relative;
    height: 0;
    max-width: 100%;
    overflow: hidden;

    img, video, iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.ratio-1-1 {
    padding-bottom: 100%; }
.ratio-3-2 {
    padding-bottom: 66.6%; }
.ratio-4-3 {
    padding-bottom: 75%; }
.ratio-5-3 {
    padding-bottom: 60%; }
.ratio-5-4 {
    padding-bottom: 80%; }
.ratio-16-9 {
    padding-bottom: 56.25%; }
.ratio-16-10,
.ratio-8-5 {
    padding-bottom: 62.5%; }



// ! ---------- SCROLL CONTAINER

// Make scrollbars invisible by exceeding 100% width

.scroll-container {
    @include layout--nav-always-visible {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: #{-1 * $scroll-container-padding};
        padding: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none; } }

.scroll-container-gradient {
    pointer-events: none;
    position: absolute;
    bottom: -1px;
    left: 1%;
    width: 98%;
    height: 4.5 * $one-line-height;
    background: linear-gradient(to top, palette('background') 10%, transparent 100%);
    @if $scroll-container-gradient-easing {
        background: linear-gradient(to top, palette('background'), $scroll-container-gradient-easing, transparent); }
    z-index: $z-index-content;
    transition: transition(opacity);
    opacity: 0;

    .package-list &,
    .template-basic-page &, {
        background: linear-gradient(to top, palette('text') 10%, transparent 100%);
        @if $scroll-container-gradient-easing {
            background: linear-gradient(to top, palette('text'), $scroll-container-gradient-easing, transparent); } }

    .visible-container-info .info &,
    .visible-container-about .about &,
    .visible-container-about .project-list &,
    .visible-container-project-list .project-list &,
    .visible-container-project-list .about &,
    .visible-container-package-list .package-list &,
    .visible-container-legal .legal &,
    .template-basic-page &,
    .landing-page__content &, {
        transition-delay: $transition-duration;
        opacity: .9; } }

.scroll-container__inner {
    @include layout--nav-always-visible {
        padding-right: $scroll-container-padding; }

    .scroll-container--inner-padding & {
        @extend %container-content-padding; } }

.scroll-container--visible-bars {
    @include layout--nav-always-visible {
        right: 0;
        overflow-y: scroll;
        .scroll-container__inner {
            padding-right: 0; } } }



// ! ---------- TEXT BLOCKS


.text-block {

    a, a:hover {}


    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: $fontsize-l;
        margin-bottom: $one-line-height--absolute; }

    p, ul, ol, figure, img, table, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height--absolute * 1.5;
        @include breakpoint(medium) {
            margin-bottom: $one-line-height--absolute; }
        &:last-child {
            margin-bottom: 0; } }

    figure img,
    table p {
        margin-bottom: 0; }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .5em;
                content: "•"; } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .4em;
                content: counter(table-ol) "."; } } } }
