// ! ---------- DEBUG

@mixin debug-bg($color) {
    @if $debug {
        background-color: $color; } }



// ! ---------- NAMED BREAKPOINTS / LAYOUTS

@mixin layout--nav-always-visible {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--nav-as-overlay {
    @include breakpoint(small down) {
        @content; } }

@mixin layout--two-column-content {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--font-size-reduced {
    @include breakpoint(medium) {
        @include breakpoint(xlarge down) {
            @content; } } }



// ! ---------- GET MEASUREMENTS FROM GRID GUTTER

$vertical-factor: 1;

@mixin grid-gutter-property($property, $gutter: $grid-column-gutter, $factor: 1) {

    @if type-of($gutter) == 'map' {
        @each $breakpoint, $value in $gutter {
            $numerical: rem-calc($value) * $factor;

            @include breakpoint($breakpoint) {
                #{$property}: $numerical; } } }

    @else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
        $numerical: rem-calc($gutter) * $factor;

        @each $side in $sides {
            #{$property}: $numerical; } } }

@mixin grid-gutter-margin($sides: top bottom, $factor: 1) {
    @each $side in $sides {
        $property: margin-#{$side};
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($property, $factor: $factor); } }

@mixin grid-gutter-padding($sides: top bottom, $factor: 1) {
    @each $side in $sides {
        $property: padding-#{$side};
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($property, $factor: $factor); } }

@mixin grid-gutter-position($sides: top, $factor: 1) {
    @each $side in $sides {
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($side, $factor: $factor); } }



// ! ---------- GET MEASUREMENTS FROM GLOBAL PADDING

@mixin global-padding($sides: top right bottom left, $factor: 1) {
    @include grid-gutter-padding($sides: $sides, $gutter: $global-padding, $factor: $factor); }

@mixin global-margin($sides: top right bottom left, $factor: 1) {
    @include grid-gutter-margin($sides: $sides, $gutter: $global-padding, $factor: $factor); }



// ! ---------- GET BORDER FROM GLOBAL BORDER WIDTH

@mixin global-border($side: all, $factor: 1, $color: null, $var: $border-width) {
    $width: $var * $factor;

    @if not $color == null {
        $color: palette($color); }

    @if $side == all {
        border: $width solid $color; }
    @else {
        border-#{$side}: $width solid $color; } }

@mixin vertical-border($side: all) {
    @include global-border($side: $side, $var: $border-width--vertical); }

@mixin horizontal-border($side: all) {
    @include global-border($side: $side, $var: $border-width--horizontal); }




// ! ---------- FONT INCLUDES

@mixin font-face($family, $weight, $style, $basename, $formats: woff2 woff ttf) {

    // Webfont: #{$family} ( #{$weight}  #{$style})
    $sources: ();

    @each $format in $formats {
        $src: null;
        @if $format == woff2 {
            $src: font-url('#{$basename}.woff2') format('woff2'); }
        @if $format == woff {
            $src: font-url('#{$basename}.woff') format('woff'); }
        @if $format == ttf {
            $src: font-url('#{$basename}.ttf') format('truetype'); }

        @if $src {
            $sources: append($sources, $src, comma); } }

    @font-face {
        font-family: $family;
        src: $sources;
        font-weight: $weight;
        font-style: $style; } }



// ! ---------- ASSET URLS

@function asset-url($filename, $folder: assets, $url-only: false, $cache-buster: $auto-cache-buster) {

    $url: $url-base;

    @if map-has-key($asset-folders, $folder) {
        $url: $url + map-get($asset-folders, $folder); }
    @else {
        @error 'Error: `#{$folder}` is not a valid asset folder.'; }

    $full-url: add-cache-buster($url + $filename, $cache-buster);

    @return if($url-only, unquote($full-url), url('#{$full-url}')); }


@function image-url($filename, $url-only: false, $cache-buster: $auto-cache-buster) {
    @return asset-url($filename, images, $url-only, $cache-buster); }

@function font-url($filename, $url-only: false, $cache-buster: $auto-cache-buster) {
    @return asset-url($filename, fonts, $url-only, $cache-buster); }

@function asset-folder() {
    @return map-get($asset-folders, assets); }

@function image-folder() {
    @return map-get($asset-folders, images); }

@function font-folder() {
    @return map-get($asset-folders, fonts); }


@function add-cache-buster($url, $cache-buster: $auto-cache-buster) {
    @if $cache-buster {

        $parts: explode($url, '.');
        $len:   length($parts);
        $new:   ();

        // Loop through parts & insert cache buster before the last one

        @for $i from 1 through $len {
            @if $i == $len {
                $new: append($new, $cache-buster); }

            $new: append($new, nth($parts, $i)); }

        @return implode($new, '.'); }

    @else {

        @return $url; } }



// ! ---------- ACCESS COLOR MAP BY FUNCTION

@function palette($name, $map: $colors) {
    @if not map-has-key($map, $name) {
        @warn 'Warning: `#{$name}` is not a valid color name.'; }
    @else {
        @return map-get($map, $name); } }



// ! ---------- SERIALIZE DATA FOR INTERCHANGE WITH JS SCRIPTS

@function map-serialize($map) {
    $str: '';

    @each $key, $value in $map {
        $str: $str + $key + '=' + $value + '&'; }

    $str: str-slice($str, 1, -2);

    @return $str; }



// ! ---------- DEFAULT TRANSITIONS

@function transition($properties, $factor: 1, $easing: default) {
    $duration: $transition-duration * $factor;

    @if $easing == default {
        $easing: $transition-easing; }

    $props: ();

    @each $p in $properties {
        $props: append($props, $p $duration $easing, comma); }

    @return $props; }



// ! ---------- CURSORS

@mixin retina-cursor($basename, $fallback: pointer, $origin: 0 0) {
    cursor: $fallback;
    cursor: image-url('#{$basename}@1x.png') $origin, $fallback;   // Legacy
    cursor: image-url('#{$basename}.svg') $origin, $fallback;      // FF
    cursor: -webkit-image-set(image-url('#{$basename}@1x.png') 1x, image-url('#{$basename}@2x.png') 2x) $origin, $fallback; }    // Webkit



// ! ---------- EXPLODE & IMPLODE STRINGS

@function explode($string, $delimiter) {
    $list: ();
    $len:  str-length($string);

    @for $i from 1 through $len {
        $str: str-index($string, $delimiter);

        @if str-length($string) >= 1 and $str == null {
            $list: append($list, $string);
            $string: ''; }

        @if type-of($str) == number {
            $each: str-slice($string, 0, ($str - 1));
            $list: append($list, $each);
            $string: str-slice($string, ($str + 1), $len); } }

    @return $list; }


@function implode($pieces, $glue: "") {
    $result: null;

    @for $i from 1 through length($pieces) {
        $piece: nth($pieces, $i);
        @if type-of($piece) == list {
            $result: unquote("#{$result}#{$glue}#{implode($piece, $glue)}"); }
        @else {
            $result: unquote("#{$result}#{$glue}#{$piece}"); } }

    @if $result != null {
        $result: str-slice($result, str-length($glue) + 1, -1); }

    @return $result; }



// ! ---------- UNIT CONVERSION


@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1); }
    @return $number; }

@function to-unit($unit, $input) {
    $units-1: (px: 0px, pt: 0pt, pc: 0pc, in: 0in, mm: 0mm, cm: 0cm, q: 0q, em: 0em, rem: 0rem, ch: 0ch, ex: 0ex, vw: 0vw, vh: 0vh, vmin: 0vmin, vmax: 0vmax, deg: 0deg, turn: 0turn, grad: 0grad, rad: 0rad);
    $units-2: (s: 0s, ms: 0ms, hz: 0Hz, khz: 0kHz, dpi: 0dpi, dpcm: 0dpcm, dppx: 0dppx, pct: 0%, percent: 0%, num: 0, number: 0);

    $all-units: map-merge($units-1, $units-2);

    $to-unit: map-get($all-units, $unit);

    // Unit not found
    @if not $to-unit {
        @error 'Could not convert to `#{$unit}` – must be a valid CSS unit';
        @return null; }

    // Number/incomparable conversion
    @if index(num number ex ch vw vh vmin vmax, $unit) {
        $value: num($input); }

    // EM/REM convertion using px as base
    @if index(em rem, unit($input)) {
        $input: 0px + num($input) * $fontsize-base/1px; }
    @if index(em rem, $unit) and not unitless($input) {
        $input: 0px + $input;
        $input: num($input) * 1px/$fontsize-base; }

    // Bug fix – resolution units seems to be flipped
    @if index(dpi dpcm dppx, $unit) {
        $units: (dppx: 0dppx, dpcm: 0dpcm, dpi: 0dpi);
        $input-unit: map-get($units, unit($input));
        $input: if(1dppx < 95dpi,num($input-unit + (num($input) + $to-unit)),$input); }

    // Convert
    @return $to-unit + $input; }




// ! ---------- CLEARFIX

%clearfix {
    @include clearfix; }



// ! ---------- HIDE ELEMENTS

@mixin hide-visually {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute; }

@mixin hide-text {
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    color: transparent; }

@mixin no-tap-highlight {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

%hide-visually {
    @include hide-visually; }

%hide-text {
    @include hide-text; }

%no-tap-highlight {
    @include no-tap-highlight; }

%truncate-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }


// ! ---------- DRAGGABLE CURSORS

%cursor-dragging {
    cursor: move !important;
    cursor: grabbing !important; }

%cursor-draggable {
    cursor: move !important;
    cursor: grab !important; }

%cursor-sorting {
    cursor: move !important; }

%cursor-sortable {
    cursor: move !important; }



// ! ---------- DISABLE SELECTION AND DRAGGING

%not-draggable {
    user-drag: none; }

%not-selectable {
    user-select: none; }




// ! ---------- NEUTRAL BUTTON STYLE

@mixin neutral-button() {

    appearance: none;
    overflow: visible;
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    background: transparent;
    font: inherit;
    line-height: normal;
    text-decoration: none;
    text-align: inherit;
    cursor: pointer;
    user-select: text;
    transition: transition(color);
    outline: none;
    &:hover {
 }        // color: palette('text')

    // Remove mystery padding in Gecko browsers

    &::-moz-focus-inner {
        padding: 0;
        border: 0; } }

%neutral-button {
    @include neutral-button(); }

%no-tap-highlight {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


@mixin hidden-scrollbars {
    &::-webkit-scrollbar {
        display: none; } }

%hidden-scrollbars {
    @include hidden-scrollbars; }

@mixin scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

%scrollable {
    @include scrollable; }
